import styled from '@emotion/styled';
import jwtDecode from 'jwt-decode';
import {Component} from 'react';

import {API_URL, URL_WEBSITE} from '../constants.js';
import {AuthPropType} from '../propTypes.js';
import {COLOR_RP_RED, SPACE} from '../styles.js';
import {notJavascript} from '../utils/validate.js';
import Button from './Button.jsx';
import Content from './Content.jsx';
import ErrorMessage from './ErrorMessage.jsx';
import Footer from './Footer.jsx';
import Header from './Header.jsx';
import {SubHeading} from './Headings.jsx';
import Page from './Page.jsx';
import PageTitle from './PageTitle.jsx';
import TextInput from './TextInput.jsx';

const STATUS = {
  COMPLETE: 'COMPLETE',
  EMAILED: 'EMAILED',
  ERROR: 'ERROR',
  EXPIRED: 'EXPIRED',
  READY: 'READY',
  SUBMITTING: 'SUBMITTING',
  TOKEN: 'TOKEN',
};

const CenteredP = styled.p({
  textAlign: 'center',
});

export default class PasswordReset extends Component {
  static propTypes = {
    auth: AuthPropType,
  };

  state = {
    status: null,
    token: null,
    decoded: null,

    redirect: null,

    error: null,

    email: null,
    password: null,
    confirm: null,
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const redirect = urlParams.get('redirect');
    const token = urlParams.get('token');
    this.setState({redirect});
    if (token) {
      const decoded = jwtDecode(token);
      const expirationDate = new Date(decoded.exp * 1000);
      const status = expirationDate < new Date() ? STATUS.EXPIRED : STATUS.TOKEN;
      this.setState({token, decoded, status});
    } else {
      this.setState({status: STATUS.READY});
    }
  }

  submitpwd = async () => {
    if (!this.state.password) {
      this.setState({error: 'Please provide a password'});
      return;
    }
    if (this.state.password !== this.state.confirm) {
      this.setState({error: 'Passwords do not match'});
      return;
    }
    if (this.state.password.length < 8) {
      this.setState({error: 'Password must be at least 8 characters'});
      return;
    }
    this.setState({status: STATUS.SUBMITTING});
    try {
      const destination = this.state.decoded?.v;
      if (!destination) {
        this.setState({status: STATUS.ERROR, error: 'Invalid Token'});
        return;
      }
      const response = await fetch(destination, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: this.state.token,
          password: this.state.password,
        }),
      });

      if (response.status !== 200) {
        const data = await response.json();
        throw new Error(data.message);
      }
    } catch (e) {
      this.setState({status: STATUS.ERROR, error: e.message});
      return;
    }
    this.setState({status: STATUS.COMPLETE});
  };

  submitEmail = async e => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_URL}password-reset-request`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.state.email,
          redirect: this.state.redirect,
        }),
      });
      if (response.status !== 200) {
        const data = await response.json();
        throw new Error(data.message);
      }
    } catch (e) {
      this.setState({status: STATUS.ERROR, error: e.message});
      return;
    }

    this.setState({status: STATUS.EMAILED});
  };

  handleInput = e => {
    this.setState({[e.target.name]: e.target.value});
  };

  renderMain() {
    if (!this.state.status) {
      return null;
    }
    if (this.state.status === STATUS.TOKEN && !this.state.token) {
      window.location.href = URL_WEBSITE;
      return <CenteredP>Redirecting...</CenteredP>;
    } else {
      switch (this.state.status) {
        case STATUS.EXPIRED:
          return (
            <div style={{width: '100%'}}>
              <CenteredP>This password reset request has expired.</CenteredP>
              <Button
                variant="solid"
                color={COLOR_RP_RED}
                onClick={() => {
                  window.location.href = '/password-reset'; // we want a page refresh
                }}
                style={{width: '100%', fontSize: 20, marginTop: SPACE * 2}}
              >
                Try again
              </Button>
            </div>
          );
        case STATUS.READY:
          return (
            <div style={{width: '100%'}}>
              <form onSubmit={this.submitEmail}>
                <TextInput
                  autoFocus
                  placeholder="Your email address"
                  name="email"
                  type="email"
                  onChange={this.handleInput}
                ></TextInput>
                <Button
                  variant="solid"
                  type="submit"
                  color={COLOR_RP_RED}
                  style={{
                    width: '100%',
                    fontSize: 20,
                    marginTop: SPACE * 4,
                  }}
                  isDisabled={!this.state.email}
                >
                  Reset password
                </Button>
              </form>
            </div>
          );

        case STATUS.EMAILED:
          return (
            <CenteredP>
              You will receive an email shortly with a password reset link. Please click on the link
              to reset your password!
            </CenteredP>
          );
        case STATUS.TOKEN:
          return (
            <div>
              <SubHeading style={{textAlign: 'center', paddingBottom: SPACE * 4}}>
                Please enter your new password
              </SubHeading>

              {this.state.error ? <ErrorMessage message={this.state.error} /> : null}

              <div className="pwdEntry">
                <TextInput
                  autoFocus
                  placeholder="New password"
                  name="password"
                  type="password"
                  onChange={this.handleInput}
                  style={{marginBottom: SPACE * 3}}
                />
                <TextInput
                  placeholder="Confirm password"
                  name="confirm"
                  type="password"
                  onChange={this.handleInput}
                ></TextInput>

                <Button
                  variant="solid"
                  color={COLOR_RP_RED}
                  onClick={this.submitpwd}
                  style={{
                    width: '100%',
                    fontSize: 20,
                    paddingLeft: SPACE * 4,
                    paddingRight: SPACE * 4,
                    marginTop: SPACE * 4,
                  }}
                  isDisabled={!this.state.password || !this.state.confirm}
                >
                  Reset password
                </Button>
              </div>
            </div>
          );
        case STATUS.SUBMITTING:
          return <CenteredP>Please wait...</CenteredP>;
        case STATUS.COMPLETE:
          if (this.state.redirect) {
            window.location.href = notJavascript(this.state.redirect);
          }
          return (
            <>
              <CenteredP>Success!</CenteredP>
              <CenteredP>Please return to the app and log in with your new password</CenteredP>
            </>
          );
        case STATUS.ERROR:
          return (
            <div>
              <ErrorMessage message={this.state.error} />
              <CenteredP>Please refresh your browser window to start again.</CenteredP>
            </div>
          );
        default:
          return null;
      }
    }
  }

  render() {
    return (
      <>
        <Header auth={this.props.auth} />
        <Content>
          <Page>
            <PageTitle title="Reset your password" />
            {this.renderMain()}
          </Page>
        </Content>
        <Footer auth={this.props.auth} />
      </>
    );
  }
}
