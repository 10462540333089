import styled from '@emotion/styled';

import {COLOR_RP_RED, SPACE} from '../styles.js';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  paddingLeft: SPACE,
  paddingRight: SPACE,
  marginTop: SPACE,
  marginBottom: SPACE * 2,
});

const Box = styled.div(props => ({
  position: 'relative',
  marginBottom: SPACE * 4,
  padding: SPACE * 4,
  width: '100%',
  borderStyle: 'solid',
  borderColor: props.isSelected ? COLOR_RP_RED : 'rgb(226, 232, 240)',
  borderWidth: 1,
  borderRadius: 12,
  color: props.isSelected ? COLOR_RP_RED : 'black',
  cursor: props.onClick ? 'pointer' : 'default',
  textAlign: 'left',
  '&:hover': {
    borderColor:
      props.isSelected && props.onClick
        ? COLOR_RP_RED
        : props.onClick
        ? 'darkgray'
        : 'rgb(226, 232, 240)',
  },
}));

const BoxHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: 24,
});

const BoxCallout = styled.div(props => ({
  position: 'absolute',
  top: -14,
  right: 12,
  padding: `${SPACE * 1.5}px ${SPACE * 3}px`,
  borderRadius: 99,

  fontSize: 12,
  fontWeight: 500,
  textTransform: 'uppercase',
  color: 'white',

  backgroundColor:
    props.isSelected && props.isClickable
      ? COLOR_RP_RED
      : props.isClickable
      ? 'darkgray'
      : 'rgb(226, 232, 240)',
}));

const Title = styled.div(props => ({
  fontSize: 17,
  fontWeight: 600,
  color: props.isSelected ? COLOR_RP_RED : 'black',
  textTransform: 'uppercase',
  paddingBottom: SPACE,
}));

const BodyText = styled.div(props => ({
  fontSize: 14,
  fontWeight: 300,
  lineHeight: 1.4,
  color: props.isSelected ? COLOR_RP_RED : 'black',
}));

export default {
  Container,
  Box,
  BoxHeader,
  BoxCallout,
  Title,
  BodyText,
};
