import PropTypes from 'prop-types';

import {SPACE} from '../styles.js';
import {H2} from './Headings.jsx';

function PageTitle(props) {
  return (
    <div
      style={{
        textAlign: 'center',
        padding: SPACE,
        marginTop: SPACE * 4,
        marginBottom: SPACE * 4,
      }}
    >
      <H2>{props.title}</H2>
    </div>
  );
}
PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageTitle;
