import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {Component} from 'react';

import {
  ACTIVE_OPACITY,
  COLOR_GRAY_SECONDARY,
  DISABLED_OPACITY,
  SPACE,
  TEXT_UNDERLINE_OFFSET,
} from '../styles.js';

const StyledButton = styled.button(props => {
  const style = {
    backgroundColor: props.color,
    borderRadius: 10,
    padding: `0 ${SPACE * 4}px`,
    fontSize: 15,
    fontWeight: 600,
    color: props.textColor || 'white',
    borderStyle: 'solid',
    borderWidth: 0,
    height: 50,
    cursor: 'pointer',
    ...props.style,
    ['&:hover']: props.activeStyle,
    ['&:active']: props.activeStyle,
  };

  if (props.variant === 'solid') {
    style.backgroundColor = props.color;
    style.color = props.textColor || 'white';
    style.borderRadius = 14;
  }

  if (props.variant === 'outline') {
    style.backgroundColor = 'transparent';
    style.color = props.textColor || props.color;
    style.borderWidth = 1;
    style.borderColor = props.color;
  }

  if (props.variant === 'link') {
    style.backgroundColor = 'transparent';
    style.color = props.textColor || props.color;
    style.textDecoration = 'underline';
    style.textUnderlineOffset = TEXT_UNDERLINE_OFFSET;
  }

  if (props.variant === 'pill') {
    style.backgroundColor = 'rgba(242, 242, 247, 1)';
    style.color = COLOR_GRAY_SECONDARY;
    style.borderRadius = 100;
  }

  if (props.disabled) {
    style.opacity = DISABLED_OPACITY;
    style.cursor = 'regular';
    style['&:hover'] = {};
    style['&:active'] = {};
  }

  return style;
});

export default class Button extends Component {
  static propTypes = {
    className: PropTypes.string, // Needed for emotion composition to work
    activeStyle: PropTypes.object,
    children: PropTypes.node.isRequired,
    color: PropTypes.string.isRequired,
    hoverStyle: PropTypes.object,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadingContent: PropTypes.node,
    onClick: PropTypes.func,
    style: PropTypes.object,
    textColor: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.oneOf(['solid', 'outline', 'link', 'pill']).isRequired,
  };

  static defaultProps = {
    activeStyle: {opacity: ACTIVE_OPACITY},
    hoverStyle: {opacity: ACTIVE_OPACITY},
    isLoading: false,
    loadingContent: null,
    onClick: () => {},
    style: {},
    type: null,
  };

  onClick = async e => {
    if (this.props.type !== 'submit') {
      e.preventDefault();
    }
    this.props.onClick(e);
  };

  render() {
    const buttonContent =
      this.props.isLoading && this.props.loadingContent
        ? this.props.loadingContent
        : this.props.children;
    return (
      <StyledButton
        className={this.props.className}
        variant={this.props.variant}
        color={this.props.color}
        disabled={this.props.isDisabled}
        style={this.props.style}
        hoverStyle={this.props.hoverStyle}
        activeStyle={this.props.activeStyle}
        textColor={this.props.textColor}
        type={this.props.type}
        onClick={this.onClick}
      >
        {buttonContent}
      </StyledButton>
    );
  }
}
