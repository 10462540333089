function getItem(key) {
  const serializedState = localStorage.getItem(key);
  if (!serializedState) {
    return null;
  }
  return JSON.parse(serializedState);
}

function setItem(key, val) {
  const serializedState = JSON.stringify(val);
  localStorage.setItem(key, serializedState);
}

function removeItem(key) {
  localStorage.removeItem(key);
}

export default {
  getItem,
  setItem,
  removeItem,
};
