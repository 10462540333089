import {Component} from 'react';

import {BASE_TITLE} from '../constants.js';
import {AuthPropType} from '../propTypes.js';
import {SPACE} from '../styles.js';
import openAppStore from '../utils/openAppStore.js';
import Content from './Content.jsx';
import DownloadAppStoreButtons from './DownloadAppStoreButtons.jsx';
import Footer from './Footer.jsx';
import Header from './Header.jsx';
import Page from './Page.jsx';
import PageTitle from './PageTitle.jsx';

export default class Download extends Component {
  static propTypes = {
    auth: AuthPropType,
  };

  componentDidMount() {
    openAppStore();
  }

  render() {
    document.title = `${BASE_TITLE} - Download`;
    return (
      <>
        <Header auth={this.props.auth} />
        <Content>
          <Page>
            <PageTitle title="Download RP Diet Coach" />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: SPACE * 6,
              }}
            >
              <DownloadAppStoreButtons />
            </div>
          </Page>
        </Content>
        <Footer auth={this.props.auth} />
      </>
    );
  }
}
