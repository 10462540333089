/* global gtag fbq */
import {APPS} from '@renper/lib/constants.mjs';
import Cookies from 'js-cookie';
import {Component} from 'react';

import {
  API_URL,
  BASE_TITLE,
  CHECKOUT_COOKIE_KEY,
  COOKIE_KEY_WOOPRA,
  LS_KEY_REFERRAL,
  PLATFORM,
  PURCHASE_TYPE,
} from '../constants.js';
import {AuthPropType} from '../propTypes.js';
import {COLOR_RP_RED, SPACE} from '../styles.js';
import history from '../utils/history.js';
import log from '../utils/log.js';
import {manage} from '../utils/payments.js';
import {recordReferralCode} from '../utils/recordReferralCode.js';
import {clearReferralCode} from '../utils/referrals.js';
import storage from '../utils/storage.js';
import User from '../utils/User.js';
import ActiveSubs from './ActiveSubs.jsx';
import Content from './Content.jsx';
import Footer from './Footer.jsx';
import Header from './Header.jsx';
import Page from './Page.jsx';
import PageTitle from './PageTitle.jsx';
import SpinnerIcon from './SpinnerIcon.jsx';
import Subscriptions from './Subscriptions.jsx';

function showError({sessionId}) {
  window.location.href = `/purchase/error?sessionId=${encodeURIComponent(sessionId)}`; // we want page refresh
}

export default class Manage extends Component {
  static propTypes = {
    auth: AuthPropType,
  };

  state = {
    isLoading: true,

    referralCode: null,
    referralIsHidden: false,
    referralErrorMessage: null,
    isLoadingReferral: false,
    showReferralForm: false,

    selectedIapPlatformId: null,
  };

  async componentDidMount() {
    let user = await User.getAndPersist();
    if (user) {
      try {
        // Always make sure this page is up to date when it loads!
        user = await this.props.auth.refresh();
      } catch (err) {
        if (err.status === 401) {
          this.props.auth.logout();

          return;
        }

        log.notify(err);
      }
    }

    const url = new URL(window.location.href);
    const apps = url.searchParams.get('apps');
    const sessionId = url.searchParams.get('session_id');
    const upgrade = url.searchParams.get('upgrade'); // value is either 'diet' or 'training'

    if (upgrade) {
      if (!user) {
        history.push('/login?redirect=${encodeURIComponent(url)}');

        return;
      }

      const inHouseAppSubs = user.activeSubscriptions.filter(
        sub =>
          sub.platform === PLATFORM.rp &&
          sub.iapPurchaseType === PURCHASE_TYPE.subscription &&
          !!sub.subscriptionId &&
          sub.access.includes(upgrade)
      );

      if (!inHouseAppSubs.length) {
        if (![APPS.diet, APPS.training].includes(upgrade)) {
          history.push('/subscribe');
        }
        history.push(`/subscribe/${upgrade}`);
      }

      const sub = inHouseAppSubs[0];
      await manage(user, sub.access, sub.platform, sub.iapPlatformId, sub.subscriptionId, true);

      return;
    }

    if (!user && !sessionId) {
      this.props.auth.logout();

      return;
    }

    let checkoutCookieData = null;
    const checkoutJson = Cookies.get(CHECKOUT_COOKIE_KEY);
    if (checkoutJson) {
      try {
        checkoutCookieData = JSON.parse(checkoutJson);
      } catch (err) {
        log.notify(err);
      }
      Cookies.remove(CHECKOUT_COOKIE_KEY);
    }

    // Clear local referral code and open app store if possible on successful checkout
    if (sessionId) {
      const queryParams = {
        apps,
      };
      const referralCode = checkoutCookieData?.referralCode;
      if (referralCode) {
        queryParams.referralCode = referralCode;
      }
      const woopraCookie = Cookies.get(COOKIE_KEY_WOOPRA);
      if (woopraCookie) {
        queryParams.woopraCookie = woopraCookie;
      }

      const completeResponse = await fetch(
        `${API_URL}purchase/complete/${encodeURIComponent(sessionId)}?${new URLSearchParams(
          queryParams
        )}`,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
          },
        }
      );

      let wasUserCreated = false;
      if (!user) {
        if (completeResponse && completeResponse.status >= 200 && completeResponse.status < 400) {
          wasUserCreated = true;
        }
      }

      if (!user && !wasUserCreated) {
        showError({sessionId});

        return;
      }

      const conversionValue = checkoutCookieData?.conversionValue ?? null;
      const isFreeTrial = conversionValue < 1;
      const eventData = isFreeTrial
        ? {
            send_to: 'AW-737560424/O8FKCIyI2boDEOiO2d8C',
          }
        : {
            send_to: String(checkoutCookieData?.platformId).includes('_training_')
              ? 'AW-737560424/LR3uCKLR-OAYEOiO2d8C'
              : 'AW-737560424/zRV8CNn3rrYDEOiO2d8C',
            // Actually, this should be a float per google ads and the reported conversion data
            // confirmed it.
            value: conversionValue / 100,
            currency: 'USD',
            transaction_id: sessionId,
          };
      gtag('event', 'conversion', eventData);

      if (isFreeTrial) {
        fbq('track', 'StartTrial', {currency: 'USD', value: 0, transaction_id: sessionId});
      } else {
        const eventType =
          checkoutCookieData?.purchaseType === 'access_pass' ? 'Purchase' : 'Subscribe';

        fbq('track', eventType, {
          currency: 'USD',
          value: conversionValue / 100,
          transaction_id: sessionId,
        });
      }

      clearReferralCode();

      window.location.href =
        `/purchase/complete` + (apps ? `?apps=${encodeURIComponent(apps)}` : ''); // want full page refresh

      return;
    }

    // Validate referral code in local storage
    const referralCode = storage.getItem(LS_KEY_REFERRAL);
    if (referralCode) {
      try {
        const {isHidden} = await recordReferralCode(referralCode);
        this.setState({referralCode, referralIsHidden: isHidden});
      } catch (err) {
        this.setState({showReferralForm: true, referralCode, referralErrorMessage: err.message});
      }
    }

    this.setState({isLoading: false});
  }

  render() {
    const user = User.get();

    if (!user || this.state.isLoading) {
      return (
        <div
          style={{display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center'}}
        >
          <SpinnerIcon color={COLOR_RP_RED} size={80} />
        </div>
      );
    }

    document.title = `${BASE_TITLE} - Manage my plans`;

    const manage = user.activeSubscriptions.length ? (
      <>
        <PageTitle title="My plans" />
        <ActiveSubs
          activeSubs={user.activeSubscriptions}
          auth={this.props.auth}
          selectedIapPlatformId={this.state.selectedIapPlatformId}
        />
      </>
    ) : null;

    const subscribe = (
      <>
        <PageTitle
          title={user.activeSubscriptions.length ? 'Choose another plan' : 'Choose a plan'}
        />
        {[APPS.training, APPS.diet].map(app => {
          const name = app === APPS.diet ? 'RP Diet Coach app' : 'RP Hypertrophy app';
          const link = app === APPS.diet ? '/subscribe/diet' : '/subscribe/training';

          return (
            <Subscriptions.Box
              key={app}
              onClick={() => {
                history.push(link);
              }}
            >
              <Subscriptions.BoxHeader style={{alignItems: 'center'}}>
                <Subscriptions.Title
                  style={{marginRight: SPACE * 2, paddingBottom: 0, textTransform: 'none'}}
                >
                  {name}
                </Subscriptions.Title>
                <div
                  style={{
                    flex: 1,
                    cursor: 'pointer',
                    color: COLOR_RP_RED,
                    textAlign: 'right',
                  }}
                >
                  View plans →
                </div>
              </Subscriptions.BoxHeader>
            </Subscriptions.Box>
          );
        })}
      </>
    );

    return (
      <>
        <Header auth={this.props.auth} />
        <Content>
          <Page>
            {manage}
            {subscribe}
          </Page>
        </Content>
        <Footer auth={this.props.auth} />
      </>
    );
  }
}
