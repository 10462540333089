import {createRoot} from 'react-dom/client';

import App from './App.jsx';
import log from './utils/log.js';
import {getStripeConnection} from './utils/payments.js';

/* Load stripe and initialize */
getStripeConnection().catch(log.notify);

const ErrorBoundary = log.createErrorBoundary();

const root = createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary
    onError={err => {
      if ((RP_ENV || NODE_ENV) !== 'production') {
        console.error(err); // eslint-disable-line no-console
      }
    }}
  >
    <App />
  </ErrorBoundary>
);
