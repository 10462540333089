import styled from '@emotion/styled';

const Page = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto 0',
  width: '100%',
  alignItems: 'center',
  maxWidth: 420,
});

export default Page;
