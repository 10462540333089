import Bugsnag from '@bugsnag/js';
import {css, Global} from '@emotion/react';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {APPS} from '@renper/lib/constants.mjs';
import {Component} from 'react';
import {Navigate, Route, Routes, unstable_HistoryRouter as HistoryRouter} from 'react-router-dom';

import Auth from './components/Auth.jsx';
import Download from './components/Download.jsx';
import LoginWithToken from './components/LoginWithToken.jsx';
import Manage from './components/Manage.jsx';
import PasswordReset from './components/PasswordReset.jsx';
import Profile from './components/Profile.jsx';
import PurchaseComplete from './components/PurchaseComplete.jsx';
import PurchaseError from './components/PurchaseError.jsx';
import RedirectLP from './components/RedirectLP.jsx';
import Referral from './components/Referral.jsx';
import RequireAuth from './components/RequireAuth.jsx';
import Subscribe from './components/Subscribe.jsx';
import {REFERRAL_QUERY_PARAM_KEY, URL_DIET_LANDING_PAGE} from './constants.js';
import {SPACE} from './styles.js';
import history from './utils/history.js';
import log from './utils/log.js';
import normalizeStyles from './utils/normalizeStyles.js';
import {recordReferralCode} from './utils/recordReferralCode.js';
import {getHasReferralCodeChanged} from './utils/referrals.js';
import {redirectWithCurrentParamsTo} from './utils/url.js';
import User from './utils/User.js';

export default class App extends Component {
  state = {
    user: User.get(),
  };

  UNSAFE_componentWillMount() {
    history.listen(function () {
      window.woopra?.track();
    });
  }

  componentDidMount() {
    if (this.state.user) {
      window.woopra
        ?.identify({
          id: this.state.user.id,
          email: this.state.user.email,
          name: this.state.user.displayName,
        })
        .push();

      Bugsnag.setUser(this.state.user.id, this.state.user.email, this.state.user.displayName);
    }

    // Remove token from URL if we have one (because of using redirect search param internally)
    const url = new URL(window.location.href);
    url.searchParams.delete('token');
    history.replace(url.toString(), null);

    this.refreshUser().catch(err => {
      if (err.status === 401) {
        this.logout();

        return;
      }

      log.notify(err);
    });
  }

  register = async body => {
    const user = await User.register(body);
    this.setState({user});

    return user;
  };

  login = async (email, password) => {
    const user = await User.login(email, password);
    this.setState({user});

    return user;
  };

  logout = (navToLogin = true) => {
    User.logout();
    this.setState({user: null});
    if (navToLogin) {
      history.push('/login');
    }
  };

  updateUser = async data => {
    const user = await User.update(data);
    this.setState({user});
    return user;
  };

  refreshUser = async () => {
    const user = await User.refresh();
    this.setState({user});
    return user;
  };

  recordReferralCodeGetParam() {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has(REFERRAL_QUERY_PARAM_KEY)) {
      return;
    }
    const referralCode = searchParams.get(REFERRAL_QUERY_PARAM_KEY);

    // Instapage dynamic text replacement will have a URL param that looks like this if someone
    // visits the landing page without a referral code:
    // https://apps.rpstrength.com/subscribe?referralCode=[referralCode]
    if (referralCode === '[referralCode]') {
      return;
    }

    if (getHasReferralCodeChanged(referralCode)) {
      recordReferralCode(referralCode).catch(log.notify);
    }
  }

  render() {
    this.recordReferralCodeGetParam();

    const auth = {
      user: this.state.user,
      register: this.register,
      login: this.login,
      refresh: this.refreshUser,
      update: this.updateUser,
      logout: this.logout,
    };

    return (
      <GoogleOAuthProvider clientId="578120908494-i6hl6m3d9hvjrsod0muj6f8u0iclani5.apps.googleusercontent.com">
        <Global
          styles={css`
            ${normalizeStyles}
            html, div, input, header, footer {
              box-sizing: border-box;
            }
            *,
            *:before,
            *:after {
              box-sizing: inherit;
            }

            html {
              height: 100%;
              width: 100%;
              min-width: 360px;
            }

            body {
              font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu,
                sans-serif;
              width: 100%;
              height: 100%;
            }

            #app {
              display: flex;
              flex-direction: column;
              height: 100%;
              width: 100%;
            }

            a {
              color: inherit;
              text-decoration: inherit;
            }
            a:visited {
              color: inherit;
            }

            button:focus,
            input:focus,
            textarea:focus,
            select:focus {
              outline: none;
            }
            p {
              margin-top: 0px;
              line-height: 1.4em;
            }

            .menubutton-header {
              position: relative;

              @media (min-width: 720px) {
                position: absolute;
                top: ${SPACE * 4}px;
                right: ${SPACE * 4}px;
              }
              @media (max-width: 719px) {
                display: none;
              }
            }

            .menubutton-footer {
              position: relative;

              @media (max-width: 719px) {
                padding-top: ${SPACE * 6}px;
                display: flex;
                justify-content: center;
              }
              @media (min-width: 720px) {
                display: none;
              }
            }
          `}
        />
        <HistoryRouter history={history}>
          <Routes>
            <Route path="/login/link" element={<LoginWithToken auth={auth} />} />
            <Route path="/login/token" element={<LoginWithToken auth={auth} />} />
            <Route path="/login" element={<Auth auth={auth} mode="login" />} />
            <Route path="/register" element={<Auth auth={auth} mode="register" />} />
            <Route path="/logout" element={<Auth auth={auth} mode="logout" />} />
            <Route path="/password-reset" element={<PasswordReset auth={auth} />} />

            {/* EVENTUALLY THIS WILL BE A PAGE THAT HAS LINKS FOR ALL PRODUCTS UNLOCKED BY A CODE */}
            <Route path="/code/:referralCode" element={<Referral app={APPS.diet} />} />

            {/* Diet Referral Routes */}
            <Route path="/diet/code/:referralCode" element={<Referral app={APPS.diet} />} />
            <Route path="/diet" element={<RedirectLP app={APPS.diet} />} />

            {/* Training Referral Routes */}
            <Route path="/training/code/:referralCode" element={<Referral app={APPS.training} />} />
            <Route
              path="/hypertrophy/code/:referralCode"
              element={<Referral app={APPS.training} />}
            />
            <Route path="/training" element={<RedirectLP app={APPS.training} />} />
            <Route path="/hypertrophy" element={<RedirectLP app={APPS.training} />} />

            <Route path="/subscribe/diet" element={<Subscribe auth={auth} app={APPS.diet} />} />
            <Route
              path="/subscribe/training"
              element={<Subscribe auth={auth} app={APPS.training} />}
            />
            <Route path="/subscribe" element={<Navigate to="/subscribe/diet" />} />

            <Route path="/purchase/complete" element={<PurchaseComplete auth={auth} />} />
            <Route path="/purchase/error" element={<PurchaseError auth={auth} />} />

            <Route path="/download" element={<Download auth={auth} />} />

            <Route path="/manage" element={<Manage auth={auth} />} />
            <Route
              path="/profile"
              element={
                <RequireAuth auth={auth}>
                  <Profile auth={auth} />
                </RequireAuth>
              }
            />

            {/* If anyone scans the QR code for a recipe, it takes them to an app download page.
             We don't have any recipe-specific web content for recipe QR code scans yet,
             but we will someday! */}
            <Route path="/recipe" element={<Navigate to="/" />} />

            {/* Catch all the rest */}
            <Route path="/" element={<CatchAll />} />
          </Routes>
        </HistoryRouter>
      </GoogleOAuthProvider>
    );
  }
}

function CatchAll() {
  if ((RP_ENV || NODE_ENV) === 'production') {
    // TODO: this should be changed to a combination diet/training landing page
    redirectWithCurrentParamsTo(URL_DIET_LANDING_PAGE);
  } else {
    return (
      <h1 style={{width: '100%', textAlign: 'center'}}>
        In production you would end up on {URL_DIET_LANDING_PAGE}
      </h1>
    );
  }
}
