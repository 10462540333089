import jwtDecode from 'jwt-decode';
import {Component} from 'react';

import {API_URL} from '../constants.js';
import {AuthPropType} from '../propTypes.js';
import {COLOR_GRAY_SECONDARY, COLOR_RP_RED, SPACE} from '../styles.js';
import afterLogin from '../utils/afterLogin.js';
import history from '../utils/history.js';
import User from '../utils/User.js';
import Button from './Button.jsx';
import Content from './Content.jsx';
import Footer from './Footer.jsx';
import Header from './Header.jsx';
import Page from './Page.jsx';
import PageTitle from './PageTitle.jsx';
import SpinnerIcon from './SpinnerIcon.jsx';
import TextInput from './TextInput.jsx';

const warningMessageStyles = {
  backgroundColor: 'orange',
  color: 'white',
  marginBottom: '16px',
  borderRadius: '3px',
  padding: '6px 10px',
};

export default class LoginWithToken extends Component {
  static propTypes = {
    auth: AuthPropType,
  };

  state = {
    tokenData: null,
    isExpired: false,

    email: new URLSearchParams(window.location.search).get('email') || null,

    message: null,
    isFormHidden: false,
    isWorking: false,
    isReady: false,
  };

  async UNSAFE_componentWillMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token') || queryParams.get('jwt');

    this.setState({token});

    if (token) {
      const tokenData = jwtDecode(token);
      const expirationDate = new Date(tokenData.exp * 1000);
      const isExpired = expirationDate < new Date();

      if (!isExpired) {
        const user = await User.loginWithJwt(token);
        afterLogin(user);

        return;
      }

      this.setState({
        tokenData,
        isExpired,
        isReady: true,
        message: (
          <div style={warningMessageStyles}>
            The login link you used has expired.
            <br />
            Please create a new one.
          </div>
        ),
      });
    } else {
      this.setState({isReady: true});
    }
  }

  handleInput = e => {
    this.setState({[e.target.name]: e.target.value});
  };

  submit = async ev => {
    ev.preventDefault();

    this.setState({isWorking: true}, async () => {
      const {email} = this.state;
      if (!email) {
        alert('Please enter an email');
      }

      let destination = this.state.tokenData?.destination;
      if (!destination) {
        const destinationUrl = new URL(window.location.href);
        for (const key of destinationUrl.searchParams.keys()) {
          if (key === 'redirect') {
            continue;
          }
          destinationUrl.searchParams.delete(key);
        }
        destinationUrl.hash = '';
        destination = destinationUrl.toString();
      }

      const showError = () => {
        this.setState({
          isWorking: false,
          message: (
            <div style={warningMessageStyles}>
              There was as problem sending the email.
              <br />
              Please double check the entered email and try again.
            </div>
          ),
        });
      };

      try {
        const res = await fetch(`${API_URL}login/link`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({email, destination}),
        });

        if (res && res.status >= 200 && res.status < 400) {
          this.setState({
            isFormHidden: true,
            isWorking: false,
            message: (
              <div>
                We sent you an email with a login link!
                <br />
                If you don&apos;t see it soon please check your spam folder.
              </div>
            ),
          });
        } else {
          showError();
        }
      } catch (e) {
        showError();
      }
    });
  };

  render() {
    if (!this.state.isReady) {
      return (
        <div style={{margin: 'auto'}}>
          <SpinnerIcon color={COLOR_RP_RED} size={80} />
        </div>
      );
    }

    return (
      <>
        <Header auth={this.props.auth} />
        <Content>
          <Page>
            <PageTitle title="Sign in" />
            <div style={{width: '100%'}}>
              <div style={{textAlign: 'center'}}>{this.state.message}</div>
              {!this.state.isFormHidden ? (
                <form onSubmit={this.submit}>
                  <TextInput
                    autoFocus
                    placeholder="Your email address"
                    name="email"
                    type="email"
                    defaultValue={this.state.email}
                    onChange={this.handleInput}
                  ></TextInput>
                  <Button
                    variant="solid"
                    type="submit"
                    color={COLOR_RP_RED}
                    style={{
                      width: '100%',
                      fontSize: 20,
                      marginTop: SPACE * 4,
                    }}
                    isDisabled={!this.state.email || this.state.isWorking}
                    isLoading={this.state.isWorking}
                    loadingContent={<SpinnerIcon size={22} />}
                  >
                    Sign in with a link
                  </Button>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      variant="link"
                      color={COLOR_GRAY_SECONDARY}
                      onClick={() => {
                        let url = '/login';
                        const searchParams = new URLSearchParams(window.location.search);
                        if (this.state.email) {
                          searchParams.set('email', this.state.email);
                        }
                        if (searchParams.size) {
                          url += '?' + searchParams.toString();
                        }
                        history.push(url);
                      }}
                    >
                      Switch to sign in with password
                    </Button>
                  </div>
                </form>
              ) : null}
            </div>
          </Page>
        </Content>
        <Footer auth={this.props.auth} />
      </>
    );
  }
}
