import {Component} from 'react';

import {EMAIL_APP_SUPPORT} from '../constants.js';
import {AuthPropType} from '../propTypes.js';
import Content from './Content.jsx';
import Footer from './Footer.jsx';
import Header from './Header.jsx';
import Page from './Page.jsx';
import PageTitle from './PageTitle.jsx';

export default class PurchaseError extends Component {
  static propTypes = {
    auth: AuthPropType,
  };

  render() {
    const url = new URL(window.location.href);
    const sessionId = url.searchParams.get('sessionId');

    document.title = `RP Purchase Error`;
    return (
      <>
        <Header auth={this.props.auth} skipSignIn />
        <Content>
          <Page>
            <PageTitle title="Purchase Error" />
            <div style={{textAlign: 'center'}}>
              <div style={{marginTop: '16px'}}>
                There was a problem completing your purchase but you may have been charged.
              </div>

              <div>
                Please contact our support team at{' '}
                <a href={`mailto:${EMAIL_APP_SUPPORT}`}>{EMAIL_APP_SUPPORT}</a> and include the
                email you used during checkout as well as a screenshot of this page which includes
                data necessary to resolve the issue.
              </div>

              <div style={{marginTop: '32px'}}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{minWidth: '100px'}}>Session ID</td>
                      <td>{sessionId}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Page>
        </Content>
        <Footer auth={this.props.auth} />
      </>
    );
  }
}
