export function redirectWithCurrentParamsTo(targetUrl, paramsBlacklist = []) {
  const currentSearchParams = new URLSearchParams(window.location.search);
  for (const param of paramsBlacklist) {
    currentSearchParams.delete(param);
  }

  const newUrl = new URL(targetUrl);

  for (const [key, val] of currentSearchParams.entries()) {
    if (!newUrl.searchParams.has(key)) {
      newUrl.searchParams.set(key, val);
    }
  }

  window.location.href = newUrl.toString();
}
