import PropTypes from 'prop-types';

import {EMAIL_APP_SUPPORT} from '../constants.js';
import {AuthPropType} from '../propTypes.js';
import {COLOR_GRAY_SECONDARY, SPACE, TEXT_UNDERLINE_OFFSET} from '../styles.js';
import Menu from './Menu.jsx';

const FOOTER_TEXT_STYLE = {
  fontSize: 13,
  fontWeight: 600,
  color: COLOR_GRAY_SECONDARY,
};

function FooterLink({text, url, style}) {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      style={{textDecoration: 'underline', textUnderlineOffset: TEXT_UNDERLINE_OFFSET, ...style}}
    >
      <span style={FOOTER_TEXT_STYLE}>{text}</span>
    </a>
  );
}
FooterLink.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  style: PropTypes.object,
};

function Footer(props) {
  return (
    <footer
      style={{
        marginTop: SPACE * 2,
        marginBottom: SPACE * 2,
        textAlign: 'center',
        ...FOOTER_TEXT_STYLE,
      }}
    >
      <div style={{position: 'relative', marginTop: SPACE * 4, paddingBottom: SPACE * 6}}>
        <FooterLink
          url="https://rpstrength.com/privacy-policy"
          text="Privacy Policy"
          style={{marginRight: SPACE * 4}}
        />
        <FooterLink
          url="https://rpstrength.com/terms-of-service"
          text="Terms of Service"
          style={{marginRight: SPACE * 4}}
        />
        <FooterLink url={`mailto:${EMAIL_APP_SUPPORT}`} text="Contact Us" />

        {props.auth.user && <Menu className="menubutton-footer" auth={props.auth} />}
      </div>
    </footer>
  );
}
Footer.propTypes = {
  auth: AuthPropType,
};

export default Footer;
