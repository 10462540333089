import styled from '@emotion/styled';

import {SPACE} from '../styles.js';

export default styled.div({
  flex: '1 0 auto',
  width: '100%',
  margin: '0 auto 0',
  padding: SPACE * 4,
});
