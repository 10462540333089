import {LS_KEY_REFERRAL} from '../constants.js';
import storage from './storage.js';

export function sanitizeReferralCode(referralCode) {
  if (referralCode) {
    return referralCode.toUpperCase().trim() || null;
  }

  return null;
}

export function getHasReferralCodeChanged(referralCode) {
  const sanitized = sanitizeReferralCode(referralCode);
  const existing = storage.getItem(LS_KEY_REFERRAL);
  return sanitized !== existing;
}

export function clearReferralCode() {
  storage.removeItem(LS_KEY_REFERRAL);
}
