import {URL_OPEN_APP} from '../constants.js';
import {redirectWithCurrentParamsTo} from './url.js';

export default function openAppStore(token) {
  const url = new URL(URL_OPEN_APP);
  if (token) {
    url.searchParams.set('token', token);
  }
  const urlString = url.toString();

  // Redirect straight to the app if possible!
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    redirectWithCurrentParamsTo(urlString);
  } else if (/android/i.test(userAgent)) {
    redirectWithCurrentParamsTo(urlString);
  }
}
