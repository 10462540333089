import Bugsnag from '@bugsnag/js';

import {LS_KEY_REFERRAL} from '../constants.js';
import {enterReferralCode} from './enterReferralCode.js';
import history from './history.js';
import log from './log.js';
import storage from './storage.js';
import {notJavascript} from './validate.js';

export default function afterLogin(user) {
  Bugsnag.setUser(user.id, user.email, user.displayName);

  window.woopra
    ?.identify({
      id: user.id,
      email: user.email,
      name: user.displayName,
    })
    .push();

  const referralCode = storage.getItem(LS_KEY_REFERRAL);
  enterReferralCode(referralCode, false, true).catch(log.notify);

  // check if redirect param!
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const redirect = params.redirect;

  if (redirect) {
    const operator = notJavascript(redirect).indexOf('?') === -1 ? '?' : '&';
    window.location.href = `${redirect}${operator}token=${user.token}`;
  } else if (user && user.activeSubscriptions.length) {
    history.push('/manage');
  } else {
    history.push('/subscribe');
  }
}
