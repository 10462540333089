import styled from '@emotion/styled';

import {SPACE} from '../styles.js';

const TextInput = styled.input({
  height: 50,
  width: '100%',
  backgroundColor: 'white',
  paddingTop: SPACE * 5,
  paddingBottom: SPACE * 5,
  paddingLeft: SPACE * 3,
  paddingRight: SPACE * 3,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'rgba(209, 209, 214, 1)',
  borderRadius: 10,
  ['&::placeholder']: {
    color: 'rgb(142,142,147)',
  },
  ['&:focus']: {
    borderColor: 'darkgray',
  },

  fontSize: 17,
  fontWeight: 300,
});

export default TextInput;
