import PropTypes from 'prop-types';
import {Navigate} from 'react-router-dom';

import {AuthPropType} from '../propTypes.js';

export default function RequireAuth({auth, children}) {
  return auth.user ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: '/register',
        search: `?redirect=${window.location.href}`,
      }}
    />
  );
}
RequireAuth.propTypes = {
  auth: AuthPropType,
  children: PropTypes.node.isRequired,
};
