export default async function handle4xxErrors(res) {
  if (res.status >= 400 && res.status < 500) {
    const data = await res.json();
    let message = data?.message || data?.msg;
    if (!data) {
      if (res.status === 401) {
        message = 'Invalid username or password';
      } else if (res.status === 409) {
        message = 'An account already exists with that email';
      } else {
        message = 'Something went wrong. Please try again.';
      }
    }

    const err = new TypeError(message);
    err.statusCode = res.status;
    throw err;
  }

  if (!res.ok) {
    const err = new TypeError('Something went wrong. Please try again');
    err.statusCode = 500;
    throw err;
  }
}
