import {URL_APP_STORE_ANDROID, URL_APP_STORE_IOS} from '../constants.js';
import ImgDownloadiOS from '../images/download-apple.png';
import ImgDownloadAndroid from '../images/download-google-play.png';
import {SPACE} from '../styles.js';

export default function DownloadAppStoreButtons() {
  return (
    <>
      <div style={{display: 'inline-block', marginRight: SPACE * 2}}>
        <a href={URL_APP_STORE_IOS} target="_blank" rel="noreferrer">
          <img src={ImgDownloadiOS} height="50px" />
        </a>
      </div>
      <div style={{display: 'inline-block', marginLeft: SPACE * 2}}>
        <a href={URL_APP_STORE_ANDROID} target="_blank" rel="noreferrer">
          <img src={ImgDownloadAndroid} height="50px" />
        </a>
      </div>
    </>
  );
}
