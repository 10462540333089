import styled from '@emotion/styled';

import {COLOR_DARK_GRAY, COLOR_GRAY_SECONDARY, SPACE} from '../styles.js';
const headingColor = COLOR_DARK_GRAY;

export const H1 = styled.h1({
  margin: 0,
  fontSize: 22,
  fontWeight: 300,
  color: 'white',
});

export const H2 = styled.h2({
  color: headingColor,
  fontSize: 24,
  fontWeight: 700,
  margin: 0,
});

export const H3 = styled.h3({
  color: headingColor,
  fontSize: 17,
  fontWight: 600,
  lineHeight: 1.2,
});

export const H4 = styled.h4({
  color: headingColor,
  fontSize: 17,
  fontWight: 700,
  lineHeight: 1.2,
  margin: 0,
  marginBottom: SPACE * 3,
});

export const SubHeading = styled.div({
  fontSize: 15,
  fontWeight: 600,
  color: COLOR_GRAY_SECONDARY,
});
