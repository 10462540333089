import {APPS} from '@renper/lib/constants.mjs';
import PropTypes from 'prop-types';

import {URL_DIET_LANDING_PAGE, URL_TRAINING_LANDING_PAGE} from '../constants.js';
import {redirectWithCurrentParamsTo} from '../utils/url.js';

export default function RedirectLP({app}) {
  if (app === APPS.diet) {
    redirectWithCurrentParamsTo(URL_DIET_LANDING_PAGE);
  }

  if (app === APPS.training) {
    redirectWithCurrentParamsTo(URL_TRAINING_LANDING_PAGE);
  }

  return null;
}

RedirectLP.propTypes = {
  app: PropTypes.oneOf(Object.values(APPS)),
};
