import PropTypes from 'prop-types';

export const AuthPropType = PropTypes.exact({
  user: PropTypes.object,
  register: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
});
