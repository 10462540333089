import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {FaCreditCard} from 'react-icons/fa';
import {IoExit} from 'react-icons/io5';

import {AuthPropType} from '../propTypes.js';
import {COLOR_GRAY_SECONDARY, SPACE} from '../styles.js';
import history from '../utils/history.js';
import Button from './Button.jsx';

const MenuContainer = styled.div(props => {
  const style = {
    position: 'absolute',
    padding: `${SPACE * 2}px`,
    backgroundColor: 'white',
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.08), 0px 0px 48px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    minWidth: 200,

    marginTop: SPACE * 2,
    right: SPACE * 2,
  };

  if (props.isNarrow) {
    delete style.right;
    style.bottom = 48;
  }

  return style;
});

const MenuItem = styled.div({
  padding: `${SPACE * 2}px ${SPACE * 2}px`,
  fontWeight: 400,
  fontSize: 15,
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0, 0.04)',
    borderRadius: 6,
  },
});

const MenuItemIconWrapper = styled.div({
  width: 20,
  height: 20,
  marginRight: SPACE * 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export function SignInMenu() {
  return (
    <div className="menubutton-header">
      <Button
        onClick={() => {
          let url = '/login';
          if (!window.location.href.includes('password-reset')) {
            url += `?redirect=${encodeURIComponent(window.location.href)}`;
          }
          history.push(url);
        }}
        variant="pill"
        color="black"
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: SPACE * 4,
          height: 40,
          justifyContent: 'center',
        }}
      >
        sign in
      </Button>
    </div>
  );
}

export default class Menu extends Component {
  static propTypes = {
    auth: AuthPropType,
    className: PropTypes.oneOf(['menubutton-header', 'menubutton-footer']),
  };

  state = {
    isMenuOpen: false,
  };

  render() {
    return (
      <div className={this.props.className}>
        <Button
          onClick={() => this.setState(prevState => ({isMenuOpen: !prevState.isMenuOpen}))}
          variant="pill"
          color="black"
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: SPACE * 4,
            height: 40,
            justifyContent: 'center',
          }}
        >
          {this.props.auth.user.email}
        </Button>
        {this.state.isMenuOpen ? (
          <MenuContainer isNarrow={this.props.className === 'menubutton-footer'}>
            <MenuItem onClick={() => history.push('/manage')}>
              <MenuItemIconWrapper>
                <FaCreditCard size={18} color={COLOR_GRAY_SECONDARY} />
              </MenuItemIconWrapper>
              Manage my plans
            </MenuItem>
            <MenuItem onClick={this.props.auth.logout}>
              <MenuItemIconWrapper>
                <IoExit size={20} color={COLOR_GRAY_SECONDARY} />
              </MenuItemIconWrapper>
              Sign out
            </MenuItem>
          </MenuContainer>
        ) : null}
      </div>
    );
  }
}
