import PropTypes from 'prop-types';
import {FaExclamationCircle} from 'react-icons/fa';

import {COLOR_RP_RED, SPACE} from '../styles.js';

function ErrorMessage({message}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: 'rgb(254, 215, 215)',
        borderRadius: 10,
        padding: SPACE * 4,
        marginBottom: SPACE * 4,
        fontWeight: 300,
        alignItems: 'center',
        color: 'rgb(26, 32, 44)',
      }}
    >
      <div style={{width: 20, height: 20, marginRight: SPACE * 3}}>
        <FaExclamationCircle size={20} color="rgb(229, 62, 62)" />
      </div>
      <div style={{lineHeight: 1.4, textAlign: 'center', fontWeight: 500, color: COLOR_RP_RED}}>
        {message}
      </div>
    </div>
  );
}
ErrorMessage.propTypes = {message: PropTypes.string.isRequired};

export default ErrorMessage;
