import {keyframes} from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {FaSpinner} from 'react-icons/fa';

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(359deg)',
  },
});

const StyledSpinner = styled(FaSpinner)({
  animation: `${spin} 2s infinite linear`,
});

function SpinnerIcon({size, ...restProps}) {
  const iconSize = size || 24;
  return <StyledSpinner size={iconSize} {...restProps} />;
}
SpinnerIcon.propTypes = {
  size: PropTypes.number,
};

export default SpinnerIcon;
