import styled from '@emotion/styled';
import {APPS} from '@renper/lib/constants.mjs';
import PropTypes from 'prop-types';

import {
  PLATFORM,
  PURCHASE_TYPE,
  URL_APP_STORE_ANDROID,
  URL_APP_STORE_IOS,
  URL_TRAINING_APP,
} from '../constants.js';
import ImgAppIcon from '../images/app-icon-180x180.png';
import {AuthPropType} from '../propTypes.js';
import {COLOR_RP_RED} from '../styles.js';
import {manage} from '../utils/payments.js';
import Subscriptions from './Subscriptions.jsx';

const AppIcon = styled.img({
  height: 40,
  width: 40,
  borderRadius: 8,
});

export default function ActiveSubs({activeSubs, auth, selectedIapPlatformId}) {
  // TODO: https://linear.app/rpstrength/issue/ENG-469/subscribe-show-users-price-information-about-their-active
  // TODO: https://linear.app/rpstrength/issue/ENG-470/subscribe-show-user-intro-promo-price-status-of-active-subscriptions

  const user = auth.user;

  return (
    <Subscriptions.Container>
      {activeSubs
        .sort((a, b) => {
          if (a.platform < b.platform) {
            return 1;
          }
          if (a.platform > b.platform) {
            return -1;
          }
          return 0;
        })
        .map(sub => {
          const isSelected = selectedIapPlatformId === sub.iapPlatformId;

          // const platformString =
          //   sub.platform === PLATFORM.ios
          //     ? 'iOS'
          //     : sub.platform === PLATFORM.android
          //     ? 'Android'
          //     : null;

          let subscriptionTitle = null;
          let downloadLink = null;
          if (sub.access) {
            if (sub.access.length === 1) {
              if (sub.access.includes(APPS.diet)) {
                subscriptionTitle = 'RP Diet Coach';
                downloadLink =
                  sub.platform === PLATFORM.rp
                    ? '/download'
                    : sub.platform === PLATFORM.ios
                    ? URL_APP_STORE_IOS
                    : URL_APP_STORE_ANDROID;
              } else if (sub.access.includes(APPS.training)) {
                subscriptionTitle = 'RP Hypertrophy';
                downloadLink = URL_TRAINING_APP;
              }
            } else {
              // TODO: handle when accessItems includes more than 1 thing
            }
          } else {
            // handle old clients that haven't refreshed yet
            subscriptionTitle =
              sub.platform === PLATFORM.rp
                ? sub.iapName
                : sub.iapName.replace('RP Diet', 'Diet App');
          }

          const isAccessPass = sub.iapPurchaseType === PURCHASE_TYPE.accessPass;

          const onClickManage = isAccessPass
            ? null
            : () => {
                manage(user, sub.access, sub.platform, sub.iapPlatformId, sub.subscriptionId);
              };

          const onClickGetApp = () => window.open(downloadLink);

          let statusLine = '';
          const expirationDate = new Date(sub.expirationDate);
          const expirationDateString = expirationDate.toLocaleDateString();
          const expiredText = expirationDate > new Date() ? 'Expires' : 'Expired';
          if (isAccessPass || sub.cancellationDate || sub.status === 'cancelled') {
            statusLine = `${expiredText} ${expirationDateString}`;
          } else {
            switch (sub.status) {
              case 'inactive':
                statusLine = 'Payment failed';
                break;
              case 'trial':
                statusLine = `Trial ends ${expirationDateString}`;
                break;
              default:
              case 'renewing':
                statusLine = `Renews ${expirationDateString}`;
                break;
            }
          }

          return (
            <Subscriptions.Box
              key={sub.iapPlatformId + sub.expirationDate}
              style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
            >
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <AppIcon src={ImgAppIcon} />
                <Subscriptions.BoxHeader style={{flexDirection: 'column', paddingLeft: 12}}>
                  <Subscriptions.Title style={{paddingBottom: 2, textTransform: 'none'}}>
                    {subscriptionTitle}
                  </Subscriptions.Title>
                  <Subscriptions.BodyText style={{flex: 1}} isSelected={isSelected}>
                    {statusLine}
                    {!isAccessPass && (
                      <div
                        style={{
                          cursor: 'pointer',
                          color: COLOR_RP_RED,
                          paddingTop: 2,
                        }}
                        onClick={onClickManage}
                      >
                        Manage plan
                      </div>
                    )}
                  </Subscriptions.BodyText>
                  {/* TODO: Make a nice little absolute positioned Apple/Android icon OVER the RP
                  app logo to differentiate the different platform subs */}
                  {/* {platformString ? (
                    <Subscriptions.BodyText>Platform: {platformString}</Subscriptions.BodyText>
                  ) : null} */}
                </Subscriptions.BoxHeader>
              </div>

              <div style={{display: 'flex', alignItems: 'center'}}>
                <div
                  style={{
                    cursor: 'pointer',
                    color: COLOR_RP_RED,
                    textAlign: 'right',
                    paddingBottom: 4,
                  }}
                  onClick={onClickGetApp}
                >
                  Open app →
                </div>
              </div>
            </Subscriptions.Box>
          );
        })}
    </Subscriptions.Container>
  );
}

ActiveSubs.propTypes = {
  activeSubs: PropTypes.array.isRequired,
  auth: AuthPropType,
  selectedIapPlatformId: PropTypes.string,
};
