import {APPS} from '@renper/lib/constants.mjs';
import {Component} from 'react';

import {URL_TRAINING_APP} from '../constants.js';
import {AuthPropType} from '../propTypes.js';
import {COLOR_RP_RED} from '../styles.js';
import Button from './Button.jsx';
import Content from './Content.jsx';
import DownloadAppStoreButtons from './DownloadAppStoreButtons.jsx';
import Footer from './Footer.jsx';
import Header from './Header.jsx';
import {H3} from './Headings.jsx';
import Page from './Page.jsx';
import PageTitle from './PageTitle.jsx';

export default class PurchaseComplete extends Component {
  static propTypes = {
    auth: AuthPropType,
  };

  render() {
    const url = new URL(window.location.href);
    const appsString = url.searchParams.get('apps');

    const apps = new Set(String(appsString).split(','));
    const multiApp = apps.length > 1;

    const content = [];
    if (this.props.auth.user) {
      if (apps.has(APPS.diet)) {
        content.push(<DownloadAppStoreButtons />);
      }
      if (apps.has(APPS.training)) {
        content.push(
          <Button
            variant="solid"
            color={COLOR_RP_RED}
            onClick={() => {
              window.location.href = URL_TRAINING_APP;
            }}
          >
            Open RP Hypertrophy
          </Button>
        );
      }
      content.push(<br />);
    }

    content.push(
      <div>
        We&apos;ve emailed you {multiApp ? 'links' : 'a link'} to your purchased app
        {multiApp ? 's' : ''} and any bundled content. Please give the email a few minutes to
        arrive. If you don&apos;t see it soon please check your spam folder.
      </div>
    );

    document.title = `RP Purchase Complete`;
    return (
      <>
        <Header auth={this.props.auth} skipSignIn />
        <Content>
          <Page>
            <PageTitle title="Purchase Complete" />
            <div style={{textAlign: 'center'}}>
              <H3>Thank you for your purchase!</H3>

              <div style={{marginTop: 16}}>
                {content.map((el, index) => (
                  <div key={index}>{el}</div>
                ))}
              </div>
            </div>
          </Page>
        </Content>
        <Footer auth={this.props.auth} />
      </>
    );
  }
}
