/*global fbq */
import {useGoogleLogin} from '@react-oauth/google';
import PropTypes from 'prop-types';
import {Component} from 'react';
import AppleSignin from 'react-apple-signin-auth';
import ReactFacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {FaApple, FaFacebook} from 'react-icons/fa';

import {BASE_TITLE, CLIENT_ID, URL_WEB} from '../constants.js';
import GLogo from '../images/g-logo.png';
import {AuthPropType} from '../propTypes.js';
import {COLOR_GRAY_SECONDARY, COLOR_RP_RED, SPACE} from '../styles.js';
import afterLogin from '../utils/afterLogin.js';
import handle4xxErrors from '../utils/handle4xxErrors.js';
import history from '../utils/history.js';
import log from '../utils/log.js';
import User from '../utils/User.js';
import {notJavascript} from '../utils/validate.js';
import validateEmail from '../utils/validateEmail.js';
import Button from './Button.jsx';
import Content from './Content.jsx';
import ErrorMessage from './ErrorMessage.jsx';
import Footer from './Footer.jsx';
import Header from './Header.jsx';
import {SubHeading} from './Headings.jsx';
import Page from './Page.jsx';
import PageTitle from './PageTitle.jsx';
import SpinnerIcon from './SpinnerIcon.jsx';
import TextInput from './TextInput.jsx';

async function getGoogleUserInfo(accessToken) {
  const res = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  await handle4xxErrors(res);

  return res.json();
}

function GoogleOAuthButton({render, onSuccess, onError, onNonOAuthError}) {
  const login = useGoogleLogin({
    onSuccess,
    onError,
    onNonOAuthError,
  });

  return render('Google', login);
}

export default class Auth extends Component {
  static propTypes = {
    auth: AuthPropType,
    mode: PropTypes.oneOf(['login', 'register', 'logout']).isRequired,
  };

  state = {
    name: '',
    email: new URLSearchParams(window.location.search)?.get('email') || '',
    password: '',
    errorMessage: '',
    errorMessageOAuth: '',
    isLoading: false,
    isLoadingApple: false,
    isLoadingFacebook: false,
    isLoadingGoogle: false,
    showForgotPassword: this.props.mode === 'login',
  };

  async componentDidMount() {
    let user = await User.getAndPersist();
    if (user) {
      try {
        // Always make sure this page is up to date when it loads!
        user = await this.props.auth.refresh();
      } catch (err) {
        if (err.status === 401) {
          this.props.auth.logout();

          return;
        }

        log.notify(err);
      }
    }

    if (this.props.auth.user) {
      afterLogin(this.props.auth.user);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.mode !== this.props.mode) {
      this.setState({
        showForgotPassword: nextProps.mode === 'login' ? true : false,
        errorMessage: '',
        errorMessageOAuth: '',
        password: '',
      });
    }
  }

  handleChangeName = e => {
    this.setState({name: e.target.value});
  };

  handleChangeEmail = e => {
    this.setState({email: e.target.value});
  };

  handleChangePassword = e => {
    this.setState({password: e.target.value});
  };

  handleLoginRegister = e => {
    e.preventDefault();

    this.setState({isLoading: true}, async () => {
      const newStatePatch = {isLoading: false, errorMessage: '', errorMessageOAuth: ''};
      let cb = () => {};
      let user;
      try {
        if (this.props.mode === 'login') {
          user = await this.props.auth.login(this.state.email, this.state.password);
        } else if (this.props.mode === 'register') {
          const isValid = validateEmail(this.state.email);
          if (!isValid) {
            throw new Error('Please enter a valid email.');
          }
          user = await this.props.auth.register({
            provider: 'password',
            displayName: this.state.name,
            email: this.state.email,
            password: this.state.password,
          });
          fbq('track', 'CompleteRegistration');
        }
        cb = () => {
          afterLogin(user);
        };
      } catch (err) {
        // Don't log notify here. Most likely will be expected error. Anything causing 500 on
        // API side will be logged from API
        newStatePatch.errorMessage = err.message;

        // account already exists
        if (err.statusCode === 409) {
          newStatePatch.showForgotPassword = true;
        }
      }

      this.setState(newStatePatch, cb);
    });
  };

  handleAppleResponse = resp => {
    this.setState({isLoadingApple: false, errorMessage: '', errorMessageOAuth: ''}, async () => {
      // Cancelled Sign-in
      if (resp?.error === 'popup_closed_by_user') {
        return;
      }

      const registrationUser = resp?.user;
      const jwtToken = resp?.authorization?.id_token;

      if (!jwtToken) {
        log.notify(new Error('Missing JWT Token'), {resp});

        return;
      }

      function parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
        );
        return JSON.parse(jsonPayload);
      }

      const parsed = parseJwt(jwtToken);

      const body = {
        provider: 'apple',
        providerId: parsed.sub,
        providerToken: resp?.authorization?.code,
        clientId: CLIENT_ID,

        email: parsed.email,
      };

      if (registrationUser?.name) {
        body.displayName = [registrationUser.name.firstName, registrationUser.name.lastName]
          .filter(Boolean)
          .join(' ');
      }

      try {
        const user = await this.props.auth.register(body);
        afterLogin(user);
      } catch (err) {
        log.notify(err);

        this.setState({errorMessageOAuth: 'Something went wrong. Please try again later'});
      }
    });
  };

  handleFacebookResponse = async resp => {
    this.setState({isLoadingFacebook: false, errorMessage: '', errorMessageOAuth: ''}, async () => {
      const facebookId = resp.userId || resp.id;

      // Cancelled Sign-in
      if (resp?.status === 'unknown' || !facebookId) {
        return;
      }

      const body = {
        provider: 'facebook',
        providerId: facebookId,
        providerToken: resp.accessToken,

        email: resp.email, // Auth APIs will normalize this automatically to provide a fake email address for phone number only FB accounts.
        displayName: resp.name,
        photoURL: resp?.picture?.data?.url,
      };

      try {
        const user = await this.props.auth.register(body);
        afterLogin(user);
      } catch (err) {
        log.notify(err);

        this.setState({errorMessageOAuth: 'Something went wrong. Please try again later'});
      }
    });
  };

  handleGoogleSuccessResponse = tokenResponse => {
    this.setState({isLoadingGoogle: false, errorMessage: '', errorMessageOAuth: ''}, async () => {
      try {
        const userInfo = await getGoogleUserInfo(tokenResponse.access_token);

        const body = {
          provider: 'google',
          providerId: userInfo.sub,
          providerToken: tokenResponse.access_token,
          providerTokenIsAccess: true,

          email: userInfo.email,
          displayName: userInfo.given_name + ' ' + userInfo.family_name,
          photoURL: userInfo.picture,
        };

        const user = await this.props.auth.register(body);
        afterLogin(user);
      } catch (err) {
        log.notify(err);

        this.setState({errorMessageOAuth: 'Something went wrong. Please try again later'});
      }
    });
  };

  handleGoogleErrorResponse = errorResponse => {
    this.setState({isLoadingGoogle: false, errorMessage: '', errorMessageOAuth: ''}, () => {
      log.notify(new Error(errorResponse.error), errorResponse);
      this.setState({errorMessageOAuth: 'Something went wrong. Please try again later'});
    });
  };

  handleGoogleNonOAuthErrorResponse = errorResponse => {
    this.setState({isLoadingGoogle: false, errorMessage: '', errorMessageOAuth: ''}, async () => {
      if (errorResponse.type === 'popup_closed') {
        return;
      }

      log.notify(new Error(errorResponse.error), errorResponse);
      this.setState({errorMessageOAuth: 'Something went wrong. Please try again later'});
    });
  };

  renderOauthButton(provider, onClick) {
    let socialIcon = null;
    switch (provider) {
      case 'Apple':
        socialIcon = <FaApple size={28} />;
        break;
      case 'Facebook':
        socialIcon = <FaFacebook size={26} />;
        break;
      case 'Google':
        socialIcon = <img src={GLogo} width={27} height={27} />;
        break;

      // no default
    }

    const iconWrapperStyle = provider !== 'Apple' ? {display: 'flex', alignItems: 'center'} : {};
    const marginTop = provider !== 'Apple' ? SPACE * 5 : 0;

    return (
      <Button
        variant="outline"
        color="black"
        onClick={e => {
          this.setState({[`isLoading${provider}`]: true}, () => onClick(e));
        }}
        isLoading={this.state[`isLoading${provider}`]}
        loadingContent={<SpinnerIcon size={22} />}
        style={{width: '100%', marginTop}}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: 30,
          }}
        >
          <div style={{...iconWrapperStyle, marginRight: SPACE * 3}}>{socialIcon}</div>
          <div
            style={{
              fontSize: 15,
              fontWeight: 600,
            }}
          >
            Continue with {provider}
          </div>
        </div>
      </Button>
    );
  }

  render() {
    const urlSearchParams = new URLSearchParams(window.location.search);

    if (this.props.mode === 'logout') {
      this.props.auth.logout(false);

      const redirect = urlSearchParams.get('redirect');
      if (redirect) {
        window.location.href = notJavascript(redirect);
      }

      return null;
    }

    const params = Object.fromEntries(urlSearchParams.entries());
    const redirect = params.redirect;

    if (this.props.auth.user && redirect) {
      return null;
    }

    let pageTitle = 'Sign in to your account';
    let submitButtonText = 'Sign in';
    let linkUrl = `/register${redirect ? `?redirect=${redirect}` : ''}`;
    let linkText = "Don't have an account?";
    let isSubmitDisabled = this.state.email === '' || this.state.password === '';

    if (this.props.mode === 'register') {
      pageTitle = 'Create your account';
      submitButtonText = 'Create account';
      linkUrl = `/login${redirect ? `?redirect=${redirect}` : ''}`;
      linkText = 'Already have an account?';
      isSubmitDisabled = isSubmitDisabled || this.state.name === '';
    }

    document.title = `${BASE_TITLE} - ${pageTitle}`;

    return (
      <>
        <Header auth={this.props.auth} skipSignIn />
        <Content>
          <Page>
            <PageTitle title={pageTitle} />
            <AppleSignin
              authOptions={{
                clientId: CLIENT_ID,
                scope: 'email name',
                redirectURI: URL_WEB + 'subscribe',
                usePopup: true,
              }}
              onSuccess={this.handleAppleResponse}
              onError={this.handleAppleResponse}
              skipScript={false}
              render={({onClick}) => this.renderOauthButton('Apple', onClick)}
            />
            <ReactFacebookLogin
              appId="471059286630664"
              autoLoad={false}
              fields="name,email,picture"
              callback={this.handleFacebookResponse}
              render={({onClick}) => this.renderOauthButton('Facebook', onClick)}
            />
            <GoogleOAuthButton
              onSuccess={this.handleGoogleSuccessResponse}
              onError={this.handleGoogleErrorResponse}
              onNonOAuthError={this.handleGoogleNonOAuthErrorResponse}
              render={(...params) => this.renderOauthButton(...params)}
            />
            {this.state.errorMessageOAuth ? (
              <div style={{paddingTop: SPACE * 4, width: '100%'}}>
                <ErrorMessage message={this.state.errorMessageOAuth} />
              </div>
            ) : null}
            <div
              style={{
                position: 'relative',
                width: '100%',
                paddingTop: SPACE * 8,
                paddingBottom: SPACE * 6,
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  top: 10,
                  backgroundColor: 'rgb(60, 60, 67, .38)',
                  height: 1,
                  width: '100%',
                }}
              ></div>
              <span
                style={{
                  position: 'relative',
                  color: COLOR_GRAY_SECONDARY,
                  backgroundColor: 'white',
                  paddingLeft: SPACE * 3,
                  paddingRight: SPACE * 3,
                  fontWeight: 600,
                  fontSize: 15,
                }}
              >
                OR
              </span>
            </div>
            <SubHeading style={{paddingBottom: SPACE * 4}}>
              Sign {this.props.mode === 'register' ? 'up' : 'in'} with email
            </SubHeading>
            <div style={{width: '100%'}}>
              {this.state.errorMessage ? <ErrorMessage message={this.state.errorMessage} /> : null}
              <form onSubmit={this.handleLoginRegister}>
                {this.props.mode === 'register' && (
                  <TextInput
                    autoFocus
                    type="text"
                    name="name"
                    id="name"
                    required
                    placeholder="Name"
                    value={this.state.name}
                    onChange={this.handleChangeName}
                    style={{marginBottom: SPACE * 3}}
                  />
                )}
                <TextInput
                  autoFocus={this.props.mode === 'login'}
                  type="email"
                  name="email"
                  id="email"
                  required
                  placeholder="Email address"
                  value={this.state.email}
                  onChange={this.handleChangeEmail}
                  style={{marginBottom: SPACE * 3}}
                />

                <TextInput
                  type="password"
                  name="password"
                  id="password"
                  required
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handleChangePassword}
                  style={{marginBottom: SPACE * 3}}
                />
                <Button
                  variant="solid"
                  color={COLOR_RP_RED}
                  type="submit"
                  isDisabled={isSubmitDisabled}
                  isLoading={this.state.isLoading}
                  loadingContent={<SpinnerIcon size={22} />}
                  style={{width: '100%', fontSize: 20, marginTop: SPACE * 2}}
                >
                  {submitButtonText}
                </Button>
              </form>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'center',
                  paddingTop: SPACE * 3,
                }}
              >
                <Button variant="link" color={'black'} onClick={() => history.push(linkUrl)}>
                  {linkText}
                </Button>

                {this.state.showForgotPassword ? (
                  <>
                    <Button
                      variant="link"
                      color={COLOR_GRAY_SECONDARY}
                      onClick={() => {
                        let url = '/login/link';
                        const searchParams = new URLSearchParams(window.location.search);
                        if (this.state.email) {
                          searchParams.set('email', this.state.email);
                        }
                        if (searchParams.size) {
                          url += '?' + searchParams.toString();
                        }
                        history.push(url);
                      }}
                    >
                      Switch to sign in with a link
                    </Button>

                    <Button
                      variant="link"
                      color={COLOR_GRAY_SECONDARY}
                      onClick={() => {
                        history.push('/password-reset');
                      }}
                    >
                      Forgot your password?
                    </Button>
                  </>
                ) : null}
              </div>
            </div>
          </Page>
        </Content>
        <Footer auth={this.props.auth} />
      </>
    );
  }
}
