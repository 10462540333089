export const SPACE = 4;

export const DISABLED_OPACITY = 0.5;

export const ACTIVE_OPACITY = 0.9;

export const COLOR_RP_RED = '#EE2D37';

export const COLOR_DARK_GRAY = 'rgb(26, 32, 44)';

export const TEXT_UNDERLINE_OFFSET = 3;

export const COLOR_GRAY_SECONDARY = 'rgb(60, 60, 67, 0.6)';
