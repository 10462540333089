/* global gtag */
import {APPS} from '@renper/lib/constants.mjs';
import {loadStripe} from '@stripe/stripe-js/pure';
import Cookies from 'js-cookie';

import {
  API_URL,
  COOKIE_KEY_WOOPRA,
  EMAIL_APP_SUPPORT,
  LS_KEY_FBC,
  LS_KEY_REFERRAL,
  ORDER_TYPE,
  PLATFORM,
  STRIPE_API_KEY,
} from '../constants.js';
import handle4xxErrors from './handle4xxErrors.js';
import log from './log.js';
import {clearReferralCode, sanitizeReferralCode} from './referrals.js';
import storage from './storage.js';

export const MANAGE_SUBS_URL_ANDROID =
  'http://play.google.com/store/account/subscriptions?package=com.rp.rpdiet&sku=';
export const MANAGE_SUBS_URL_IOS = 'itms-apps://apps.apple.com/account/subscriptions';

let _stripe = null;
export async function getStripeConnection(retry = 0) {
  if (_stripe !== null) {
    return _stripe;
  }

  if (retry > 4) {
    throw new Error('Stripe failed to load after 5 tries');
  }

  try {
    _stripe = await loadStripe(STRIPE_API_KEY);
    return _stripe;
  } catch (err) {
    await new Promise(resolve => setTimeout(resolve, Math.pow(3, retry) * 100));
    return getStripeConnection(retry + 1);
  }
}

export async function checkout(user, platformId, product) {
  const body = {
    platformId,
    referralCode: sanitizeReferralCode(storage.getItem(LS_KEY_REFERRAL)),
  };

  const woopraCookie = Cookies.get(COOKIE_KEY_WOOPRA);
  if (woopraCookie) {
    body.woopraCookie = woopraCookie;
  }

  const fbc = storage.getItem(LS_KEY_FBC);
  if (fbc) {
    body.fbc = fbc;
  }

  const headers = {
    accept: 'application/json',
    'content-type': 'application/json',
  };
  if (user) {
    headers.authorization = `Bearer ${user.token}`;
  }

  const checkoutResponse = await fetch(`${API_URL}purchase/checkout`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  });

  await handle4xxErrors(checkoutResponse);

  const data = await checkoutResponse.json();

  if (data.type === ORDER_TYPE.stripe && data.sessionId) {
    if (gtag) {
      gtag('event', 'begin_checkout', {
        iapPlatformId: body.platformId,
        referralCode: body.referralCode,
      });
    }

    const stripe = await getStripeConnection();
    await stripe.redirectToCheckout({sessionId: data.sessionId});
  } else {
    // In case we provision a free access pass for the user
    clearReferralCode();

    window.location.href = `/purchase/complete?apps=${product.access.join(',')}`; // we want full page refresh
  }
}

export async function manage(
  user,
  accessItems,
  platform,
  platformId,
  subscriptionId = null,
  upgrade = false
) {
  // TODO: handle when accessItems contains multiple items
  const accessItem = accessItems[0];
  if (accessItem === APPS.diet && platform === PLATFORM.ios) {
    window.open(MANAGE_SUBS_URL_IOS, '_blank');

    return;
  }

  if (accessItem === APPS.diet && platform === PLATFORM.android) {
    window.open(MANAGE_SUBS_URL_ANDROID, '_blank');

    return;
  }

  const fetchObject = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.token}`,
    },
  };
  if (platformId) {
    const body = {
      accessItem,
      platformId,
    };
    if (subscriptionId) {
      body.subscriptionId = subscriptionId;
    }
    if (upgrade) {
      body.upgrade = upgrade;
    }
    fetchObject.body = JSON.stringify(body);
  }

  const customerPortalRes = await fetch(
    `${API_URL}stripe/create-customer-portal-session`,
    fetchObject
  );

  if (!customerPortalRes.status === 200) {
    log.notify(new Error("Couldn't create customer portal session", {response: customerPortalRes}));
    window.alert(
      `Sorry, something went wrong. Please try again or contact ${EMAIL_APP_SUPPORT} for assistance.`
    );

    return;
  }

  const data = await customerPortalRes.json();

  if (!data.url) {
    log.notify(
      new Error("Customer portal response didn't contain a data url", {
        response: customerPortalRes,
        data,
      })
    );
    window.alert(
      `Sorry, something went wrong. Please try again or contact ${EMAIL_APP_SUPPORT} for assistance.`
    );

    return;
  }

  window.location.href = data.url;
}
