import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {Component} from 'react';

import RpLogo from '../images/rp-logo.png';
import {AuthPropType} from '../propTypes.js';
import {SPACE} from '../styles.js';
import Menu, {SignInMenu} from './Menu.jsx';

const HeaderBackground = styled.header({
  position: 'relative',
  height: 80,
  backgroundColor: 'white',
});

const Content = styled.div({
  position: 'relative',
  overflow: 'visible',
  width: 'auto',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  textAlign: 'center',
  margin: '0 auto 0',
  paddingTop: SPACE * 10,
  paddingBottom: SPACE * 10,
});

export default class Header extends Component {
  state = {
    navDrawerOpen: false,
  };

  static propTypes = {
    skipSignIn: PropTypes.bool,
    auth: AuthPropType,
  };

  static defaultProps = {
    skipSignIn: false,
  };

  onNavClose = () => {
    this.closeNav();
  };

  openNav = () => {
    this.setState({navDrawerOpen: true});
  };

  closeNav = () => {
    this.setState({navDrawerOpen: false});
  };

  render() {
    return (
      <>
        <HeaderBackground>
          <Content>
            <img style={{height: 36}} src={RpLogo} />
          </Content>
          {this.props.auth.user ? (
            <Menu className="menubutton-header" auth={this.props.auth} />
          ) : this.props.skipSignIn ? null : (
            <SignInMenu />
          )}
        </HeaderBackground>
      </>
    );
  }
}
