import {API_URL} from '../constants.js';
import handle4xxErrors from './handle4xxErrors.js';
import User from './User.js';

export async function enterReferralCode(referralCode, isUserEntry = false, associateOnly = false) {
  if (!referralCode) {
    return;
  }

  const url = new URL(`${API_URL}referrals/enter/${referralCode}`);
  if (isUserEntry) {
    url.searchParams.set('isUserEntry', '1');
  }
  if (associateOnly) {
    url.searchParams.set('associateOnly', '1');
  }

  const headers = {};
  const user = User.get();
  if (user) {
    headers.authorization = `Bearer ${user.token}`;
  }
  const res = await fetch(url.toString(), {
    method: 'POST',
    headers,
  });

  await handle4xxErrors(res);

  return res.json();
}
