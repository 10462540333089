/* global gtag */
import {LS_KEY_REFERRAL, REFERRAL_QUERY_PARAM_KEY} from '../constants.js';
import {enterReferralCode} from './enterReferralCode.js';
import history from './history.js';
import {sanitizeReferralCode} from './referrals.js';
import storage from './storage.js';

export async function recordReferralCode(referralCode, isUserEntry = false) {
  const previousReferralCode = storage.getItem(LS_KEY_REFERRAL);

  const sanitized = sanitizeReferralCode(referralCode);
  storage.setItem(LS_KEY_REFERRAL, sanitized);

  if (gtag) {
    gtag('event', 'rp-referral-code', {
      event_category: 'ecommerce',
    });
  }

  // Remove referralCode from query param (esp. useful for tracking codes not intended to be user visible)
  const url = new URL(window.location.href);
  if (url.searchParams.has(REFERRAL_QUERY_PARAM_KEY)) {
    url.searchParams.delete(REFERRAL_QUERY_PARAM_KEY);
    history.replace(url.toString(), null);
  }

  const associateOnly = sanitized === previousReferralCode;

  return enterReferralCode(sanitized, isUserEntry, associateOnly);
}
