import {Component} from 'react';

import {BASE_TITLE} from '../constants.js';
import {AuthPropType} from '../propTypes.js';
import Content from './Content.jsx';
import Footer from './Footer.jsx';
import Header from './Header.jsx';
import Page from './Page.jsx';
import PageTitle from './PageTitle.jsx';

export default class Profile extends Component {
  static propTypes = {
    auth: AuthPropType,
  };

  state = {
    name: this.props.auth.user.displayName,
    email: this.props.auth.user.email,
  };

  render() {
    document.title = `${BASE_TITLE} - Account`;

    return (
      <>
        <Header auth={this.props.auth} />
        <Content>
          <Page>
            <PageTitle title="Edit Profile" />
            <div>User Info</div>
            <div>{this.state.name}</div>
            <div>{this.state.email}</div>
            <div>Change password</div>

            <div>Billing Location</div>
            <div>{this.state.countryCode}</div>
            <div>{this.state.usStateCode}</div>
          </Page>
        </Content>
        <Footer auth={this.props.auth} />
      </>
    );
  }
}
